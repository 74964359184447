import { FormErrors, SerivceError, UnifiedErrors } from '~/types';
import { AxiosError } from 'axios';

export const useErrorParser = () => {
  const rawErrors = ref<FormErrors | SerivceError>({});

  const errors = computed<UnifiedErrors>(() => {
    if (
      rawErrors.value &&
      typeof rawErrors.value['error'] === 'object' &&
      !Array.isArray(rawErrors.value.error)
    ) {
      return {
        [rawErrors.value.error['code']]: [rawErrors.value.error['message']],
      } as UnifiedErrors;
    } else {
      return rawErrors.value as UnifiedErrors;
    }
  });

  const setRawErrors = (e: unknown | null) => {
    if (e === null) {
      rawErrors.value = {};
    } else if (e instanceof AxiosError && e.response) {
      rawErrors.value = e.response.data;
    }
  };

  return { setRawErrors, errors };
};
